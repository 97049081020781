<template>
  <div class="home">
    <ys-page-list
        ref="table"
        method="$get"
        keyID="4983C1A3822636C5B6BFF5F63892D2DE"
        action="/doc/category/List"
        :params="params"
        :multiBtn="multiBtn"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="项目名称" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <!-- <Detail :tabName="tabName" :mid="detailID"></Detail> -->
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="900" title="新增/修改检查资料">
      <Edit :mid="detailID" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: 'projects',
  mixins: [pageList],
  components: {
    // Detail,
    Edit: () => import('./edit.vue')
  },
  data() {
    return {
      params: {
        keyword: '',
      },
      putInFlag: false,
      IDs: [],
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 60, title: '排序', key: 'index', align: 'center'},
        {minWidth: 400, title: '需检查资料', key: 'itemName', align: 'left',tooltip:true},
        {width: 180, title: '所属项目类型', key: 'projectCategory', align: 'center', renderConfig: {type: 'baseData', parentName: '项目类型'}},
        {minWidth: 200, title: '备注', key: 'desc', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/doc/category/Remove', {ids: this.tableSelectItem.map(item => item.id)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length <= 0
            },
          ]
        },
        {
          children: [
            // {
            //   type: 'detail',
            //   title: '查看检查记录',
            //   disabled: this.tableSelectItem.length !== 1 || true // 暂时先不开发
            // },
          ]
        }
      ]
    },
  },
  mounted() {
    this.getList()
  },
  methods: {}
}
</script>
